const TextField = ({ label, autoFocus, name }) => {
  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="" className="text-black font-semibold">
        {label}
      </label>
      <input
        type="text"
        name={name}
        autoFocus={autoFocus} // Set autoFocus attribute based on the prop
        className="border border-black/20 rounded outline-none focus:outline-none hover:border-black focus:border-secondary transition-all duration-300 placeholder:text-gray p-1.5"
      />
    </div>
  );
};

export default TextField;
