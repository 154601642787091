import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { swipeConfidenceThreshold, swipePower } from "../../services/swipe";
import { RightArrow } from "../icons";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const Carousel1 = ({ images }) => {
  // hooks
  const [currentWidth, setCurrentWidth] = useState(468.75);

  // variables

  return (
    <div>
      <Swiper
        className="relative flex gap-4 w-full z-0"
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={4}
        onSlideChange={(swiper) => {
          setCurrentWidth(swiper.slidesSizesGrid[0]);
        }}
        // onSwiper={(swiper) => console.log(swiper)}
        direction="horizontal"
        navigation={{
          prevEl: "#carousel1-btn-prev", // Previous button selector
          nextEl: "#carousel1-btn-next", // Next button selector
        }}
        loop
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 4,
          },
        }}
      >
        <div
          className="absolute w-[468.75px] h-full bg-[#D9D9D9]/70 top-0 left-0 z-[1] hidden lg:block"
          style={{ width: `${currentWidth}px` }}
        ></div>
        <div
          className="absolute w-[468.75px] h-full bg-[#D9D9D9]/70 top-0 right-0 z-[1] hidden lg:block"
          style={{ width: `${currentWidth}px` }}
        ></div>
        <button
          id="carousel1-btn-prev"
          className="rotate-180 hover:scale-105 transition-all absolute w-16 h-16 flex items-center justify-center top-[calc(50%-2rem)] left-4 lg:left-20 border border-black rounded-full z-10"
        >
          <RightArrow />
        </button>
        {images.map((item, index) => {
          return (
            <SwiperSlide>
              <div className="relative">
                <img src={item} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
        <button
          id="carousel1-btn-next"
          className="absolute w-16 h-16 hover:scale-105 transition-all flex items-center justify-center top-[calc(50%-2rem)] right-4 lg:right-20 border border-black rounded-full z-10"
        >
          <RightArrow />
        </button>
      </Swiper>
    </div>
  );
};

export default Carousel1;
