import { useEffect, useState } from "react";
import { RightArrow } from "../icons";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";

const Carousel2 = ({ items, show = 3 }) => {
  // variables

  return (
    <div className="relative">
      <Swiper
        className="relative flex gap-4 w-full z-0"
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={show}
        // onSwiper={(swiper) => console.log(swiper)}
        direction="horizontal"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: show,
          },
        }}
        navigation={{
          prevEl: "#carousel1-btn-prev-2", // Previous button selector
          nextEl: "#carousel1-btn-next-2", // Next button selector
        }}
        loop
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
      <button
        id="carousel1-btn-prev-2"
        className="rotate-180 absolute w-16 h-16 flex items-center justify-center top-[calc(50%-2rem)] left-4 lg:-left-20 border border-black rounded-full z-10"
      >
        <RightArrow />
      </button>
      <button
        id="carousel1-btn-next-2"
        className="absolute w-16 h-16 flex items-center justify-center top-[calc(50%-2rem)] right-4 lg:-right-20 border border-black rounded-full z-10"
      >
        <RightArrow />
      </button>
    </div>
  );
};

export default Carousel2;
