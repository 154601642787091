import { useEffect, useState } from "react";
import { Carousel2 } from "../../../design/components";
import { StarIcon } from "../../../design/icons";

const Testimonials = () => {
  return (
    <section className="w-full max-w-[1500px] mx-auto px-4 py-10">
      <h2 className="text-4xl lg:text-7xl text-center font-bold text-black">
        What Do Our Clients Say?
      </h2>

      <div className="w-full mt-10 ">
        <Carousel2
          items={[
            <Card
              rating={5}
              name="Jenna Smith"
              position="Housewife"
              image="/assets/home/client-1.png"
              feedback="Outstanding! Housekeeping Headquarters transformed my home into a spotless haven. Professional & trustworthy, they're my top choice for impeccable cleaning services!"
            />,
            <Card
              rating={5}
              name="Danny Robertson"
              position="Office Manager"
              image="/assets/home/client-2.png"
              feedback="For a pristine workspace, Housekeeping Headquarters is unbeatable. Their team ensures every corner is spotless, leaving our office refreshed and ready for success. Exceptional service!"
            />,
            <Card
              rating={5}
              name="Michael Chen"
              position="Remote Worker"
              image="/assets/home/client-3.png"
              feedback="Kudos to Housekeeping Headquarters Efficient, friendly eco-conscious. My apartment has never looked better. Satisfied customer and a definite repeat client, for sure!"
            />,
            <Card
              rating={5}
              name="Jenna Smith"
              position="Housewife"
              image="/assets/home/client-1.png"
              feedback="Outstanding! Housekeeping Headquarters transformed my home into a spotless haven. Professional & trustworthy, they're my top choice for impeccable cleaning services!"
            />,
            <Card
              rating={5}
              name="Danny Robertson"
              position="Office Manager"
              image="/assets/home/client-2.png"
              feedback="For a pristine workspace, Housekeeping Headquarters is unbeatable. Their team ensures every corner is spotless, leaving our office refreshed and ready for success. Exceptional service!"
            />,
            <Card
              rating={5}
              name="Michael Chen"
              position="Remote Worker"
              image="/assets/home/client-3.png"
              feedback="Kudos to Housekeeping Headquarters Efficient, friendly eco-conscious. My apartment has never looked better. Satisfied customer and a definite repeat client, for sure!"
            />,
          ]}
        />
      </div>
    </section>
  );
};

export default Testimonials;

const Card = ({ rating, feedback, name, position, image }) => {
  const [r, setR] = useState([]);

  const createRating = () => {
    for (let i = 0; i < rating; i++) {
      setR((prevState) =>
        prevState.length < rating ? [...prevState, true] : prevState
      );
    }
  };

  useEffect(() => {
    createRating();
  }, []);

  return (
    <div className="border border-[#D1D1DC] rounded-lg">
      <div className="p-8">
        <div className="text-[#F8C617] flex items-center gap-2">
          {r.map((item, index) => (
            <StarIcon key={index} />
          ))}
        </div>
        <h4 className="text-[#7F8490] leading-relaxed mt-4">{feedback}</h4>
      </div>
      <div className="p-8 flex border-t border-[#D1D1DC] gap-4">
        <div className="w-16 h-16 rounded-full overflow-hidden">
          <img src={image} alt="" width="100%" height="100%" />
        </div>
        <div>
          <h3 className="text-black font-bold">{name}</h3>
          <h4 className="text-[#7F8490]">{position}</h4>
        </div>
      </div>
    </div>
  );
};
