export const PhoneIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={15 * size}
      height={16 * size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.641 11.8078L13.9529 14.7862C13.8769 15.1486 13.6577 15.3298 13.2953 15.3298C9.63088 15.3298 6.5005 14.0323 3.90417 11.4373C1.30921 8.84099 0.0117223 5.71061 0.0117225 2.04616C0.0117225 1.68379 0.192908 1.4646 0.555281 1.3886L3.53366 0.700499C3.89603 0.605495 4.15322 0.739179 4.30523 1.10155L5.67939 4.30794C5.81376 4.61331 5.74725 4.88068 5.47988 5.11004L3.76167 6.51271C4.2774 7.63783 4.97433 8.6347 5.85244 9.5033C6.73054 10.3719 7.72266 11.0641 8.82878 11.5798L10.2599 9.86161C10.4703 9.59424 10.7377 9.52773 11.0621 9.6621L14.2684 11.0363C14.6118 11.1883 14.736 11.4455 14.641 11.8078Z"
        fill={color}
      />
    </svg>
  );
};

export const RightArrow = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={18 * size}
      height={15 * size}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.68359 8.49419L14.2902 8.49419L10.4301 12.8542C10.054 13.2782 10.1156 13.9092 10.5661 14.2622C11.0177 14.6162 11.687 14.5582 12.0621 14.1342L17.3746 8.13419C17.416 8.08719 17.4373 8.03219 17.4681 7.98019C17.4936 7.93819 17.5244 7.90219 17.5435 7.85619C17.5913 7.74119 17.62 7.62019 17.62 7.49819L17.6211 7.49419L17.62 7.49019C17.62 7.36819 17.5913 7.24718 17.5435 7.13218C17.5244 7.08618 17.4936 7.05018 17.4681 7.00818C17.4373 6.95618 17.416 6.90118 17.3746 6.85418L12.0621 0.854144C11.8517 0.617142 11.55 0.494141 11.2461 0.494141C11.006 0.494141 10.7648 0.570141 10.5661 0.726143C10.1156 1.07914 10.054 1.71015 10.4301 2.13415L14.2902 6.49418L1.68359 6.49418C1.09709 6.49418 0.621094 6.94218 0.621094 7.49419C0.621094 8.04619 1.09709 8.49419 1.68359 8.49419Z"
        fill={color}
      />
    </svg>
  );
};

export const ExitIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={29 * size}
      height={30 * size}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.8789H19.6667M19.6667 14.8789L15 10.8789M19.6667 14.8789L15 18.8789"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.334 8.21354C10.3501 5.3135 10.4787 3.74295 11.5032 2.71845C12.6749 1.54688 14.5605 1.54688 18.3317 1.54688H19.665C23.4362 1.54688 25.3219 1.54688 26.4934 2.71845C27.665 3.89002 27.665 5.77563 27.665 9.54687V20.2135C27.665 23.9847 27.665 25.8703 26.4934 27.0419C25.4689 28.0665 23.8983 28.1951 20.9981 28.2113M10.334 21.5469C10.3501 24.4469 10.4787 26.0174 11.5032 27.0419C12.3583 27.897 13.5937 28.1281 15.6647 28.1905"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const TwitterIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={30 * size}
      height={25 * size}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9993 0.999736C28.7225 1.90037 27.3088 2.58922 25.8127 3.03974C25.0097 2.11642 23.9425 1.46199 22.7554 1.16497C21.5683 0.86795 20.3187 0.942663 19.1755 1.37901C18.0322 1.81535 17.0506 2.59227 16.3633 3.60469C15.676 4.61711 15.3163 5.81618 15.3327 7.03974V8.37307C12.9895 8.43383 10.6677 7.91415 8.57403 6.86033C6.48034 5.80651 4.67977 4.25125 3.33268 2.33307C3.33268 2.33307 -2.00065 14.3331 9.99935 19.6664C7.25339 21.5304 3.98222 22.465 0.666016 22.3331C12.666 28.9997 27.3327 22.3331 27.3327 6.99974C27.3315 6.62834 27.2957 6.25786 27.226 5.89307C28.5868 4.55106 29.5471 2.85669 29.9993 0.999736Z"
        fill={color}
      />
    </svg>
  );
};

export const InstagramIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={33 * size}
      height={32 * size}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 2.66663H9.66667C5.98477 2.66663 3 5.65139 3 9.33329V22.6666C3 26.3485 5.98477 29.3333 9.66667 29.3333H23C26.6819 29.3333 29.6667 26.3485 29.6667 22.6666V9.33329C29.6667 5.65139 26.6819 2.66663 23 2.66663Z"
        fill={color}
        stroke="black"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6656 15.1599C21.8302 16.2696 21.6406 17.4029 21.124 18.3986C20.6073 19.3943 19.7898 20.2018 18.7878 20.7061C17.7858 21.2105 16.6502 21.386 15.5427 21.2078C14.4351 21.0296 13.412 20.5067 12.6187 19.7135C11.8255 18.9202 11.3026 17.8971 11.1244 16.7895C10.9462 15.682 11.1217 14.5464 11.6261 13.5444C12.1304 12.5424 12.9379 11.7249 13.9336 11.2082C14.9293 10.6916 16.0626 10.502 17.1723 10.6666C18.3042 10.8344 19.3521 11.3619 20.1612 12.171C20.9703 12.9801 21.4978 14.028 21.6656 15.1599Z"
        fill={color}
        stroke="black"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.666 8.6665H23.6793"
        stroke="black"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const YoutubeIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={39 * size}
      height={32 * size}
      viewBox="0 0 39 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_765_265)">
        <path
          d="M36.6476 7.67672C36.4562 6.9121 36.0665 6.21153 35.5177 5.64576C34.9689 5.08 34.2805 4.66907 33.5221 4.4545C30.7509 3.77783 19.6665 3.77783 19.6665 3.77783C19.6665 3.77783 8.58206 3.77783 5.81095 4.51894C5.05251 4.73352 4.36413 5.14444 3.81534 5.71021C3.26655 6.27598 2.87677 6.97655 2.68539 7.74117C2.17824 10.5534 1.93016 13.4063 1.94428 16.2639C1.9262 19.1431 2.1743 22.0177 2.68539 24.8512C2.89638 25.592 3.29489 26.266 3.8424 26.8078C4.38992 27.3497 5.06794 27.7412 5.81095 27.9445C8.58206 28.6856 19.6665 28.6856 19.6665 28.6856C19.6665 28.6856 30.7509 28.6856 33.5221 27.9445C34.2805 27.7299 34.9689 27.319 35.5177 26.7532C36.0665 26.1875 36.4562 25.4869 36.6476 24.7223C37.1509 21.9312 37.3989 19.1 37.3887 16.2639C37.4068 13.3848 37.1587 10.5102 36.6476 7.67672Z"
          fill={color}
          stroke="black"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.041 21.5323L25.3049 16.2639L16.041 10.9956V21.5323Z"
          fill="black"
          stroke="black"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_765_265">
          <rect
            width="38.6667"
            height="32"
            fill={color}
            transform="translate(0.332031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LocationIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={15 * size}
      height={20 * size}
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.42689 19.0056C5.82821 18.1341 5.14895 17.1502 4.38909 16.0538C3.62924 14.9592 3.08813 14.1879 2.76577 13.7397C2.44163 13.2898 2.0679 12.7355 1.64458 12.0766C1.22126 11.4159 0.94052 10.9483 0.802365 10.6737C0.66598 10.401 0.517197 10.0529 0.356016 9.62961C0.193063 9.20629 0.0929893 8.83876 0.0557936 8.52702C0.0185979 8.21529 0 7.84776 0 7.42444C0 5.43181 0.697862 3.73852 2.09359 2.34456C3.48754 0.948839 5.18083 0.250977 7.17346 0.250977C9.16609 0.250977 10.8603 0.948839 12.256 2.34456C13.6499 3.73852 14.3469 5.43181 14.3469 7.42444C14.3469 7.84776 14.3283 8.21529 14.2911 8.52702C14.2539 8.83876 14.1539 9.20629 13.9909 9.62961C13.8297 10.0529 13.6809 10.401 13.5446 10.6737C13.4064 10.9483 13.1257 11.4159 12.7023 12.0766C12.279 12.7355 11.9053 13.2898 11.5811 13.7397C11.2588 14.1879 10.7177 14.9592 9.95782 16.0538C9.19797 17.1502 8.5187 18.1341 7.92003 19.0056C7.74645 19.2553 7.49759 19.3802 7.17346 19.3802C6.84932 19.3802 6.60047 19.2553 6.42689 19.0056ZM5.06127 9.53662C5.64755 10.1211 6.35161 10.4134 7.17346 10.4134C7.99531 10.4134 8.69937 10.1211 9.28564 9.53662C9.87015 8.95035 10.1624 8.24628 10.1624 7.42444C10.1624 6.60259 9.87015 5.89853 9.28564 5.31225C8.69937 4.72775 7.99531 4.43549 7.17346 4.43549C6.35161 4.43549 5.64755 4.72775 5.06127 5.31225C4.47677 5.89853 4.18452 6.60259 4.18452 7.42444C4.18452 8.24628 4.47677 8.95035 5.06127 9.53662Z"
        fill={color}
      />
    </svg>
  );
};

export const StarIcon = ({ size = 1, color = "currentColor" }) => {
  return (
    <svg
      width={29 * size}
      height={27 * size}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4755 0.0998535L17.7466 10.167H28.3318L19.7682 16.3888L23.0392 26.456L14.4755 20.2341L5.91193 26.456L9.18294 16.3888L0.619322 10.167H11.2045L14.4755 0.0998535Z"
        fill={color}
      />
    </svg>
  );
};

export const CheckIcon = ({ size = 1, color = "currentColor" }) => (
  <svg
    width={30 * size}
    height={26 * size}
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.2426 0.6995C26.575 0.499564 26.9719 0.436152 27.35 0.522573C27.7281 0.608994 28.0581 0.838528 28.2706 1.163L29.7556 3.428C29.9492 3.72389 30.032 4.07866 29.9895 4.42968C29.9469 4.7807 29.7818 5.10542 29.5231 5.3465L29.5186 5.3525L29.4976 5.372L29.4121 5.4515L29.0746 5.774C27.2071 7.5854 25.3962 9.4543 23.6446 11.378C20.3491 15.002 16.4356 19.745 13.8016 24.347C13.0666 25.631 11.2711 25.907 10.2061 24.7985L0.478614 14.6915C0.339212 14.5466 0.230363 14.3752 0.158538 14.1874C0.0867137 13.9996 0.053379 13.7992 0.0605155 13.5983C0.0676519 13.3974 0.115114 13.1999 0.20008 13.0177C0.285047 12.8354 0.405784 12.6721 0.555114 12.5375L3.49511 9.8855C3.75348 9.65257 4.08477 9.51667 4.43228 9.50107C4.77979 9.48548 5.12192 9.59115 5.40011 9.8L10.3636 13.5215C18.1171 5.876 22.5136 2.942 26.2426 0.6995Z"
      fill={color}
    />
  </svg>
);
