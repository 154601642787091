const Button = ({
	children,
	variant = "contained",
	fullWidth,
	theme = "primary",
	...props
}) => {
	let commonClass = `py-1.5 px-4 rounded font-semibold hover:scale-[1.01] transition-all ${
		fullWidth && "w-full flex items-center justify-center"
	}`;
	let animateClass = ``;

	if (variant === "outline") {
		let themeClass = "";
		if (theme === "primary") {
			themeClass = "text-primary  border-primary hover:text-white";
			animateClass = "bg-primary";
		} else if (theme === "black") {
			themeClass = "text-black border-black hover:text-white";
			animateClass = "bg-black";
		} else if (theme === "white") {
			themeClass = "text-white border-white hover:text-black";
			animateClass = "bg-white";
		} else if (theme === "secondary") {
			themeClass = "text-secondary border-secondary hover:text-white";
			animateClass = "bg-secondary";
		}
		return (
			<button
				{...props}
				className={`${commonClass} ${themeClass} border relative group overflow-hidden z-0 transition-all duration-500 ease-in-out`}>
				<span
					className={`${animateClass} absolute w-40 h-40 rotate-45 -top-40 right-32 transition-all duration-500 ease-in-out  group-hover:right-2 group-hover:-top-16 z-0`}
				/>
				<span className="relative z-[1]">{children}</span>
			</button>
		);
	} else {
		let themeClass = "";
		if (theme === "primary") {
			themeClass = "text-white bg-primary";
			animateClass = "bg-secondary";
		} else if (theme === "secondary") {
			themeClass = "text-white bg-secondary";
			animateClass = "bg-[#1D61C5]";
		} else if (theme === "white") {
			themeClass = "text-secondary bg-white";
			animateClass = "bg-[#eee]";
		}
		return (
			<button
				{...props}
				className={`${commonClass} ${themeClass} relative group overflow-hidden z-0 transition-all duration-500 ease-in-out items-center justify-center`}>
				<span
					className={`absolute w-9 h-9 rounded-full top-0 left-[calc(50%-18px)] group-hover:w-full group-hover:left-0 group-hover:rounded-none opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out ${animateClass}`}
				/>
				<span className="relative z-[1]">{children}</span>
			</button>
		);
	}
};

export default Button;
