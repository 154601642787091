import { PulseLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div className="w-60 mb-4">
        <img src="/assets/logo.svg" alt="logo" width="100%" height="100%" />
      </div>
      {/* <h2 className="text-2xl my-4 font-semibold">Coming Soon...</h2> */}
      <PulseLoader color="#36d7b7" />
    </div>
  );
};

export default Loading;
