import { Button } from "../../design/components";
import { LocationIcon, PhoneIcon } from "../../design/icons";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = {
  lat: 47.6062,
  lng: -122.3321,
};

const containerStyle = {
  width: "100%",
  minHeight: "350px",
};

const position = {
  lat: 47.6062,
  lng: -122.3321,
};
const mapOptions = {
  disableDefaultUI: true, // Disable default controls
  styles: [
    {
      featureType: "poi", // Points of interest
      stylers: [{ visibility: "off" }], // Turn off visibility
    },
    {
      featureType: "transit", // Transit lines
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }], // Turn off visibility
    },
  ],
};

const Locations = () => {
  const GoogleAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <div className="my-10 flex gap-10 flex-col-reverse lg:flex-row">
      <div className="w-full lg:w-[47.5rem]">
        <LoadScript googleMapsApiKey={`${GoogleAPI}`}>
          {typeof window !== "undefined" && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              options={mapOptions}
              zoom={15}
            >
              <Marker position={position} zIndex={1000} />
            </GoogleMap>
          )}
        </LoadScript>{" "}
      </div>
      <div>
        <h3 className="text-4xl text-center lg:text-left lg:text-5xl font-bold text-black">
          Proudly Serving
        </h3>
        <p className="my-4 text-black text-lg lg:text-2xl text-center lg:text-left">
          We proudly clean all of these areas. If you're not sure we service
          your area please give us a call{" "}
          <a href="tel:+253-988-5813">
            <span className="inline-flex items-center text-primary gap-2 ml-4">
              <PhoneIcon size={1.3} /> 253-988-5813
            </span>
          </a>
        </p>
        <div className="mb-4">
          <h4 className="text-black text-xl lg:text-2xl font-bold">
            Here are the cities we service:
          </h4>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-4 mt-6">
            <Location name="Bellevue" />
            <Location name="Everett" />
            <Location name="Maple Valley" />
            <Location name="Renton" />
            <Location name="Bothell" />
            <Location name="Hunts Point" />
            <Location name="Medina" />
            <Location name="Sammamish" />
            <Location name="Bridle Trails" />
            <Location name="Issaquah" />
            <Location name="Mercer Island" />
            <Location name="SeaTac" />
            <Location name="Brier" />
            <Location name="Kenmore" />
            <Location name="Mill Creek" />
            <Location name="Shoreline" />
            <Location name="Burien" />
            <Location name="Kent" />
            <Location name="Mountlake Terrace" />
            <Location name="Tukwila" />
            <Location name="Clyde Hill" />
            <Location name="Kirkland" />
            <Location name="Mukilteo" />
            <Location name="Union Hill" />
            <Location name="Covington" />
            <Location name="Lake Forest Park" />
            <Location name="Newcastle" />
            <Location name="Woodinville" />
            <Location name="Des Moines" />
            <Location name="Lynnwood" />
            <Location name="Redmond" />
            <Location name="Yarrow Point" />
          </div>
        </div>
        <a href="mailto:Headquarters@housekeepinghq.com">
          <Button theme="secondary">Get a quote</Button>
        </a>
      </div>
    </div>
  );
};

export default Locations;

const Location = ({ name }) => {
  return (
    <div className="text-dark font-bold flex gap-2 items-center">
      <LocationIcon />
      <h4>{name}</h4>
    </div>
  );
};
