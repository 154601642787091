const Clock = () => {
  return (
    <section className="w-full max-w-[1500px] mx-auto py-10 px-4 flex flex-col-reverse lg:flex-row gap-10">
      <div className="w-full lg:w-[25rem]">
        <img src="/assets/home/clock.png" alt="" width="100%" height="100%" />
      </div>
      <div className="w-full lg:w-[calc(100%-25rem-2.5rem)]">
        <h2 className="text-black  text-2xl lg:text-7xl font-bold text-center lg:text-left">
          We understand that letting someone into your home is a serious
          decision.
        </h2>
        <p className="text-lg lg:text-2xl my-8 text-center lg:text-left">
          We only hire and partner with professional cleaners who pass a
          national and local background check, have passed our rigorous
          certification process, while training in our homes. HOUSEKEEPING
          HEADQUARTERS and Team are licensed and insured.
        </p>
      </div>
    </section>
  );
};

export default Clock;
