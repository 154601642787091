const Cleaning = () => {
  return (
    <section
      className="w-full max-w-[1500px] mx-auto px-4 py-10"
      id="cleaning-id"
    >
      <h2 className="text-black text-5xl lg:text-7xl font-bold text-center">
        <span className="text-primary">Cleaning</span> Service
      </h2>
      <h4 className="text-center text-lg lg:text-2xl font-medium lg:max-w-[60%] mx-auto my-8">
        HOUSEKEEPING HEADQUARTERS provides top-notch cleaning services for homes
        and offices in the Puget Sound Region.
      </h4>
      <img
        src="/assets/home/cleaning.png"
        alt=""
        width="100%"
        height="100%"
        className="rounded-3xl"
      />
    </section>
  );
};

export default Cleaning;
