import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function CenterMode() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 4,
    speed: 500
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
      <img src="/assets/home/how-1.png" alt="" />
          <img src="/assets/home/how-2.png" alt="" />
          <img src="/assets/home/how-3.png" alt="" />
          <img src="/assets/home/how-4.png" alt="" />
          <img src="/assets/home/how-5.png" alt="" />
          <img src="/assets/home/how-6.png" alt="" />
      </Slider>
    </div>
  );
}

export default CenterMode;
