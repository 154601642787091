import { useState } from "react";
import { routes } from "../../constants/routes";
import { ExitIcon, PhoneIcon } from "../../design/icons";
import { Twirl as Hamburger } from "hamburger-react";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header className="w-full bg-primary fixed  top-0 z-50">
      <div className="w-full max-w-[1500px] mx-auto px-4 py-4 lg:py-6 flex items-center justify-between relative z-10 bg-primary">
        <a href={routes.home} className="w-40 lg:w-60">
          <img src="/assets/logo-real.svg" alt="" width="100%" height="100%" />
        </a>
        <div className="hidden lg:flex items-center gap-12 ">
          <nav className="flex items-center gap-8">
            <Link href={routes.home}>Home</Link>
            <Link href={routes.residential}>Residential</Link>
            <Link href={routes.services}>Services</Link>
            <Link href={routes.locations}>Location</Link>
            <Link href={routes.joinCrew}>Join Crew</Link>
          </nav>
          <a
            href="tel:+253-988-5813"
            class="relative inline-flex items-center justify-start  px-5 py-3 overflow-hidden font-bold rounded-full group"
          >
            <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
            <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-5"></span>
            <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-primary flex items-center gap-2">
              <PhoneIcon />
              <span>253-988-5813</span>
            </span>
            <span class="absolute inset-0 border-2 border-white rounded-full"></span>
          </a>
        </div>
        <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
          <Hamburger size={24} toggled={menuOpen} toggle={setMenuOpen} />
        </div>
      </div>

      <div
        className={`w-screen h-full pb-4 fixed top-[80px] lg:hidden bg-primary z-50 transition-all duration-300 ease-in-out ${
          menuOpen ? "right-0" : "-right-[110%]"
        }`}
      >
        {menuOpen && (
          <div className="px-5">
            <nav className="p-4 flex flex-col gap-6 text-white   mt-6">
              <Link href={routes.home}>Home</Link>
              <Link href={routes.residential}>Residential</Link>
              <Link href={routes.services}>Services</Link>
              <Link href={routes.locations}>Location</Link>
              <Link href={routes.joinCrew}>Join Crew</Link>

              <div className="flex items-center gap-2">
                <PhoneIcon />
                <span>253-988-5813</span>
              </div>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;

const Link = ({ children, href }) => {
  return (
    <a href={href} className="text-white font-semibold cursor-pointer">
      {children}
    </a>
  );
};
