import {
  Cleaning,
  Clock,
  Features,
  Hero,
  How,
  Services,
  Testimonials,
  Trust,
} from "../components/home/sections";

const Home = () => {
  return (
    <main className="mt-[100px]">
      <Hero />
      <Services />
      <Cleaning />
      <Clock />
      <Trust />
      <How />
      <Testimonials />
      <section className="w-full max-w-[1500px] mx-auto px-4 lg:px-40 py-4 lg:py-10">
        <h4 className="text-black lg:text-2xl text-center">
          Our top priority is to offer our clients efficient cleaning services
          that make their lives more comfortable, enjoyable, & stress-free. We
          appreciate their feedback & nothing makes us happier than a positive
          comment or referral about our work to their friends, families or
          neighbors!
        </h4>
      </section>
      <Features />
    </main>
  );
};

export default Home;
